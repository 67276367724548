import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const Blogs = () => {
  const [data, setData] = useState([]); // Store fetched data
  const [loading, setLoading] = useState(false); // Handle loading state
  const [searchQuery, setSearchQuery] = useState(""); // Store search query
  const baseURL = process.env.REACT_APP_API_URL;

  // Fetch data on component mount
  useEffect(() => {
    setLoading(true);
    axios
      .get("https://mayaitsolution.com/api/GetBlogDetailList/")
      .then((res) => {
        const fetchedData = res.data; // Adjust this based on your API response
        setData(
          Array.isArray(fetchedData) ? fetchedData : fetchedData.data || []
        );
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // Handle delete of blog post
  const handleDelete = (id) => {
    const confirmDelete = window.confirm("Would you like to delete this?");
    if (confirmDelete) {
      axios
        .get(`https://mayaitsolution.com/api/DeleteBlogDetail/${id}`)
        .then((res) => {
          setData(data.filter((item) => item.id !== id));
        })
        .catch((err) => console.error(err));
    }
  };

  // Handle change in search input
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter data based on search query (title matching)
  const filteredData = data.filter((item) =>
    item.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Show loading screen while data is being fetched
  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100 bg-light">
        <h4 className="text-center text-dark">
          <span>Loading...</span>
        </h4>
      </div>
    );
  }

  return (
    <div className="pb-lg-5 pb-3">
      {/* Search input */}
      {/* <div className="search-bar bor17 of-hidden pos-relative mb-5">
        <input
          type="text"
          placeholder="Search by title"
          value={searchQuery}
          onChange={handleSearchChange}
          className="search-input stext-103 cl2 plh4 size-116 p-l-28 p-r-55"
        />
      </div> */}

      {/* Display filtered blogs */}
      {filteredData.length > 0 ? (
        filteredData.map((d) => (
          <div key={d.id}>
            <div>
              <Link
                to={`/blog-details/${d.id}`}
                className="hov-img0 how-pos5-parent pt-lg-2 pt-2"
              >
                <img
                  src={
                    d.ogiImage
                      ? `${baseURL}${d.ogiImage}`
                      : "/assets/images/product-placeholder.jpg"
                  }
                  alt={d.title || "Product Image"}
                  className="hov-img0 how-pos5-parent pt-lg-3 pt-2"
                />
              </Link>
              <div className="pt-lg-3 pt-3">
                <h6 className="pb-lg-1 pb-0">
                  <Link
                    to={`/blog-details/${d.id}`}
                    className="ltext-108 cl12 hov-cl1 trans-04"
                  >
                    {d.title || "N/A"}
                  </Link>
                </h6>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div>No blogs found matching your search.</div>
      )}
    </div>
  );
};

export default Blogs;
