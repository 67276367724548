import React, { useState } from "react";
import Blogs from "./blogs1";
import { Link } from "react-router-dom";
const Home = () => {
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isMenuOpen, setMenuOpen] = useState(false); // Track if the menu is open
  // This function handles the search bar toggle
  const handleSearchClick = () => {
    setSearchOpen((prevState) => !prevState); // Toggle the search bar visibility on each click
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const toggleMenu = () => {
    // When the menu button is clicked, close the search bar if it's open
    if (isSearchOpen) {
      setSearchOpen(false);
    }
    setMenuOpen(!isMenuOpen); // Toggle the menu
  };
  return (
    <div>
      <div className="container-fluid navbar-full fixed-top">
        <section>
          <nav>
            <div className="container-fluid ms-2">
              <div id="cm-primary-nav" className="navbar navbar-expand-md">
                {/* Left side button (Hamburger or Cross) */}
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleMenu} // Toggle menu and close search if open
                >
                  {/* Use Bootstrap's cross icon */}
                  <span
                    className={`navbar-toggler-icon ${
                      isMenuOpen ? "navbar-toggler-close" : ""
                    }`}
                  />
                </button>

                {/* Navbar menu items */}
                <div
                  className={`collapse navbar-collapse ${
                    isMenuOpen ? "show" : ""
                  }`}
                  id="navbarScroll"
                >
                  <ul
                    className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll"
                    style={{ "--bs-scroll-height": "100px" }}
                  >
                    <li className="nav-item dropdown me-lg-3 me-0">
                      <Link
                        className="nav-link dropdown-toggle px-lg-3 px-2"
                        to="#" // OnClick: Navigate to this page
                        role="button"
                        aria-expanded="false"
                      >
                        श्री रामचरितमानस पाठ
                      </Link>
                      <ul className="dropdown-menu">
                        <li>
                          <Link className="dropdown-item" to="#">
                            काण्ड स्वरूप
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="#">
                            नवाह्नपरायण स्वरूप
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="#">
                            मासपारायण स्वरूप
                          </Link>
                        </li>
                      </ul>
                    </li>

                    <li className="nav-item dropdown me-lg-3 me-0">
                      <Link
                        className="nav-link dropdown-toggle px-lg-3 px-1"
                        to="#"
                        role="button"
                        aria-expanded="false"
                      >
                        श्री रामचरितमानस पाठ
                      </Link>
                      <ul className="dropdown-menu">
                        <li>
                          <Link className="dropdown-item" to="#">
                            बालकाण्ड
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="#">
                            अयोध्याकाण्ड
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="#">
                            अरण्यकाण्ड
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="#">
                            किष्किन्धाकाण्ड
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="#">
                            सुंदरकाण्ड
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="#">
                            उत्तरकाण्ड
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="#">
                            लंकाकाण्ड
                          </Link>
                        </li>
                      </ul>
                    </li>

                    <li className="nav-item dropdown me-lg-3 me-0">
                      <Link
                        className="nav-link dropdown-toggle px-lg-3 px-2"
                        to="#"
                        role="button"
                        aria-expanded="false"
                      >
                        RamCharitManas English
                      </Link>
                      <ul className="dropdown-menu">
                        <li>
                          <Link className="dropdown-item" to="#">
                            Know the Metres in RamCharitManas
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/bal-kand-english"
                          >
                            Bal Kand with English Translation
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="#">
                            Ayodhya Kanda in English Translation
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Fixed Position Search Button */}
            <div
              className="search-top"
              style={{
                position: "fixed",
                top: "10px",
                right: "20px",
                zIndex: 1000,
              }}
            >
              <form
                className="d-flex"
                role="search"
                onSubmit={(e) => e.preventDefault()}
              >
                <i
                  className="bi bi-search text-white fs-3"
                  onClick={handleSearchClick} // On first click, toggle search bar
                  aria-expanded={isSearchOpen}
                ></i>
              </form>
            </div>

            {/* Search Bar Overlay */}
            {isSearchOpen && (
              <div
                className="search-bar"
                style={{
                  position: "fixed",
                  top: "60px",
                  right: "20px",
                  zIndex: 1000,
                }}
              >
                <input
                  type="text"
                  placeholder="Search by title"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  className="form-control search-input"
                />
                <button className="search-icon" type="submit">
                  <i className="bi bi-search text-white fs-3"></i>
                </button>
              </div>
            )}
          </nav>
        </section>
      </div>

      {/* Content page */}
      <section className="bg0 p-t-62 pb-lg-5 pb-2">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-lg-9 pb-lg-5 pt-lg-2 pt-3">
              <div className="p-r-0 p-r-0-lg">
                <Blogs />
              </div>
            </div>
            <div className="col-md-4 col-lg-3 pb-lg-5 pb-2">
              <div className="side-menu pt-lg-5 pt-3">
                <div className="pt-lg-2 pt-3">
                  <h6 className="mtext-112 cl2 pb-3">
                    सम्पूर्ण तुलसी साहित्य (Complete TulasiDas Literature)
                  </h6>
                  <ul>
                    <li className="bor18">
                      <a
                        href="#"
                        className="dis-block stext-115 cl6 hov-cl1 trans-04 p-tb-8 p-lr-4"
                      >
                        विनयपत्रिका (हिंदी अर्थ सहित)
                      </a>
                    </li>
                    <li className="bor18">
                      <a
                        href="#"
                        className="dis-block stext-115 cl6 hov-cl1 trans-04 p-tb-8 p-lr-4"
                      >
                        कवितावली (हिंदी अर्थ सहित)
                      </a>
                    </li>
                    <li className="bor18">
                      <a
                        href="#"
                        className="dis-block stext-115 cl6 hov-cl1 trans-04 p-tb-8 p-lr-4"
                      >
                        वैराग्यसंदीपनी (हिंदी अर्थ सहित)
                      </a>
                    </li>
                    <li className="bor18">
                      <a
                        href="#"
                        className="dis-block stext-115 cl6 hov-cl1 trans-04 p-tb-8 p-lr-4"
                      >
                        जानकी मंगल (हिंदी अर्थ सहित)
                      </a>
                    </li>
                    <li className="bor18">
                      <a
                        href="#"
                        className="dis-block stext-115 cl6 hov-cl1 trans-04 p-tb-8 p-lr-4"
                      >
                        रामचरितमानस (हिंदी अर्थ सहित)
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="pt-lg-2 pt-2">
                  <h6 className="mtext-112 cl2 pb-3">
                    सम्पूर्ण तुलसी साहित्य (Complete TulasiDas Literature)
                  </h6>
                  <ul>
                    <li className="bor18">
                      <a
                        href="#"
                        className="dis-block stext-115 cl6 hov-cl1 trans-04 p-tb-8 p-lr-4"
                      >
                        विनयपत्रिका (हिंदी अर्थ सहित)
                      </a>
                    </li>
                    <li className="bor18">
                      <a
                        href="#"
                        className="dis-block stext-115 cl6 hov-cl1 trans-04 p-tb-8 p-lr-4"
                      >
                        कवितावली (हिंदी अर्थ सहित)
                      </a>
                    </li>
                    <li className="bor18">
                      <a
                        href="#"
                        className="dis-block stext-115 cl6 hov-cl1 trans-04 p-tb-8 p-lr-4"
                      >
                        वैराग्यसंदीपनी (हिंदी अर्थ सहित)
                      </a>
                    </li>
                    <li className="bor18">
                      <a
                        href="#"
                        className="dis-block stext-115 cl6 hov-cl1 trans-04 p-tb-8 p-lr-4"
                      >
                        जानकी मंगल (हिंदी अर्थ सहित)
                      </a>
                    </li>
                    <li className="bor18">
                      <a
                        href="#"
                        className="dis-block stext-115 cl6 hov-cl1 trans-04 p-tb-8 p-lr-4"
                      >
                        रामचरितमानस (हिंदी अर्थ सहित)
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="pt-lg-2 pt-2">
                  <h6 className="mtext-112 cl2 p-b-3">
                    सम्पूर्ण तुलसी साहित्य (Complete TulasiDas Literature)
                  </h6>
                  <ul>
                    <li className="bor18">
                      <a
                        href="#"
                        className="dis-block stext-115 cl6 hov-cl1 trans-04 p-tb-8 p-lr-4"
                      >
                        विनयपत्रिका (हिंदी अर्थ सहित)
                      </a>
                    </li>
                    <li className="bor18">
                      <a
                        href="#"
                        className="dis-block stext-115 cl6 hov-cl1 trans-04 p-tb-8 p-lr-4"
                      >
                        कवितावली (हिंदी अर्थ सहित)
                      </a>
                    </li>
                    <li className="bor18">
                      <a
                        href="#"
                        className="dis-block stext-115 cl6 hov-cl1 trans-04 p-tb-8 p-lr-4"
                      >
                        वैराग्यसंदीपनी (हिंदी अर्थ सहित)
                      </a>
                    </li>
                    <li className="bor18">
                      <a
                        href="#"
                        className="dis-block stext-115 cl6 hov-cl1 trans-04 p-tb-8 p-lr-4"
                      >
                        जानकी मंगल (हिंदी अर्थ सहित)
                      </a>
                    </li>
                    <li className="bor18">
                      <a
                        href="#"
                        className="dis-block stext-115 cl6 hov-cl1 trans-04 p-tb-8 p-lr-4"
                      >
                        रामचरितमानस (हिंदी अर्थ सहित)
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Footer */}
      <footer className="bg3 pt-lg-5 pt-4">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-lg-4  pb-lg-5 pb-1">
              <h4 className="stext-301 cl0 p-b-8 m-l-30">About</h4>
              <ul>
                <li className="p-b-10">
                  <a href="#" className="stext-107 cl7 hov-cl1 trans-04">
                    श्री रामचरितमानस पाठ
                  </a>
                </li>
                <li className="p-b-10">
                  <a href="#" className="stext-107 cl7 hov-cl1 trans-04">
                    स्तुति/ स्तोत्र संग्रह
                  </a>
                </li>
                <li className="p-b-10">
                  <a href="#" className="stext-107 cl7 hov-cl1 trans-04">
                    वाल्मीकि रामायण अर्थ सहित
                  </a>
                </li>
                <li className="p-b-10">
                  <a href="#" className="stext-107 cl7 hov-cl1 trans-04">
                    वाल्मीकि रामायण अर्थ सहित
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-sm-6 col-lg-4 pb-lg-5 pb-1">
              <h4 className="stext-301 cl0 p-b-8 m-l-30">Know more</h4>
              <ul>
                <li className="p-b-10">
                  <a href="#" className="stext-107 cl7 hov-cl1 trans-04">
                    About RamCharit.in
                  </a>
                </li>
                <li className="p-b-10">
                  <a href="#" className="stext-107 cl7 hov-cl1 trans-04">
                    Disclaimer of RamCharit.in
                  </a>
                </li>
                <li className="p-b-10">
                  <a href="#" className="stext-107 cl7 hov-cl1 trans-04">
                    Contact RamCharit.in
                  </a>
                </li>
                <li className="p-b-10">
                  <a href="#" className="stext-107 cl7 hov-cl1 trans-04">
                    शब्दावली | Glossary
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-sm-6 col-lg-4 pb-lg-5 pb-1">
              <h4 className="stext-301 cl0 p-b-8 ">GET IN TOUCH</h4>
              {/* <p className="stext-107 cl7 size-201">
              
              </p> */}
              <div className="p-t-1">
                <a href="#" className="fs-18 cl7 hov-cl1 trans-04 m-r-16">
                  <i className="fa fa-facebook" />
                </a>
                <a href="#" className="fs-18 cl7 hov-cl1 trans-04 m-r-16">
                  <i className="fa fa-instagram" />
                </a>
                <a href="#" className="fs-18 cl7 hov-cl1 trans-04 m-r-16">
                  <i className="fa fa-pinterest-p" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-section">
          <p className="footer">
            Copyright © 2024
            <a href="#" title="RamCharitManas blogs">
              <span> Minnieworld Blogs (Minnieworld.com)</span>
            </a>
            . All rights reserved.
          </p>
        </div>
      </footer>
      {/* Back to top */}
      <div className="btn-back-to-top" id="myBtn">
        <span className="symbol-btn-back-to-top">
          <i className="zmdi zmdi-chevron-up" />
        </span>
      </div>
    </div>
  );
};

export default Home;
